import { chainIdByName } from '../chainIdByName'
import { SHIBUYA_TOKENS_BY_SYMBOL as tokens } from '../tokens/shibuya'
import { ChainIdByLPTokens } from './types'

export const SHIBUYA_LP_TOKENS = {
  apolo_astr: {
    address: 'Z6QTdZubAm8SA71XJb1x415e9Q9PtuGcTsgZ4WaVPnhZBUk',
    token: tokens.APLO,
    quoteToken: tokens.wASTAR,
    chainId: chainIdByName.shibuya
  },
  usdc_astr: {
    address: 'bVZHeY66R45uVm4mi1Y9JBV719YBb7dsyKk1z6m4sWQ57C3',
    token: tokens.USDC,
    quoteToken: tokens.wASTAR,
    chainId: chainIdByName.shibuya
  },
  usdt_astr: {
    address: 'YNcB5F4p6MbGwQyoUfysmDKD2GWsnp6YjBB73mBB2mG5HjH',
    token: tokens.USDT,
    quoteToken: tokens.wASTAR,
    chainId: chainIdByName.shibuya
  },
  usdt_usdc: {
    address: 'aBNQSpNjNcRwT6EKiV7Q9n845DuDW2ZthxZQ6DbXghKy41w',
    token: tokens.USDT,
    quoteToken: tokens.USDC,
    chainId: chainIdByName.shibuya
  },
  usdt_aplo: {
    address: 'XnZrYMMvJHfrsnYVVt6C9GUpqfNLhazG5CbUt7bVp6yeFhB',
    token: tokens.USDT,
    quoteToken: tokens.APLO,
    chainId: chainIdByName.shibuya
  },
  usdc_aplo: {
    address: 'WBRFed3pKnqHfHCgmhMnbekGSRjokDy4CkEEA45duEKsnF5',
    token: tokens.USDC,
    quoteToken: tokens.APLO,
    chainId: chainIdByName.shibuya
  }
}

export const BASIC_LPTOKENS_FOR_ROUTE_PATH_SHIBUYA: ChainIdByLPTokens = {
  [chainIdByName.shibuya]: [
    SHIBUYA_LP_TOKENS.apolo_astr,
    SHIBUYA_LP_TOKENS.usdt_astr,
    SHIBUYA_LP_TOKENS.usdc_astr,
    SHIBUYA_LP_TOKENS.usdt_usdc,
    SHIBUYA_LP_TOKENS.usdt_aplo,
    SHIBUYA_LP_TOKENS.usdc_aplo,
  ]
}
