import BigNumber from 'bignumber.js'
import { useEffect, useMemo, useState } from 'react'
import { Token } from '@/constants/tokens/types'
import { computeBestTradePathForExactAmountIn, computeBestTradePathForExactAmountOut } from '@/utils/swap/computeBestTradePath'
import { TradeType } from '@/constants/tradeType'


interface Prop {
  tokenIn?: Token,
  tokenOut?: Token,
  amountIn?: BigNumber,
  amountOut?: BigNumber,
  possiblePaths?: string[][],
  router?: any,
  tradeType?: TradeType
}

export const useBestTradeRoutePath = ({
  tokenIn,
  amountIn,
  tokenOut,
  amountOut,
  possiblePaths,
  router,
  tradeType
}: Prop) => {
  const [bestTradePath, setBestTrandePath] = useState<string[]>()
  const [maxAmountOut, setMaxAmountOut] = useState<BigNumber>()
  const [minAmountIn, setMinAmountIn] = useState<BigNumber>()

  useEffect(() => {
    if (!tokenIn || !amountIn || !possiblePaths || !router) { return }
    if (tradeType != TradeType.EXACT_INPUT) { return }

    computeBestTradePathForExactAmountIn({
      tokenIn,
      amountIn,
      possiblePaths,
      router
    }).then(result => {
      if (result) {
        const { bestTradePath: path, maxAmountOut: amount } = result
        setBestTrandePath(path)
        setMaxAmountOut(amount)
      }
    })
  }, [amountIn, possiblePaths, router, tokenIn, tradeType])

  useEffect(() => {
    if (!tokenOut || !amountOut || !possiblePaths || !router) { return }
    if (tradeType != TradeType.EXACT_OUTPUT) { return }
    computeBestTradePathForExactAmountOut({
      tokenOut,
      amountOut,
      possiblePaths,
      router
    }).then(result => {
      if (result) {
        const { bestTradePath: path, minAmountIn: amount } = result
        setBestTrandePath(path)
        setMinAmountIn(amount)
      }
    })
  }, [amountOut, possiblePaths, router, tokenOut, tradeType])

  return useMemo(() => {
    return {
      bestTradePath,
      maxAmountOut,
      minAmountIn
    }
  }, [bestTradePath, maxAmountOut, minAmountIn])
}
