
import BigNumber from 'bignumber.js'
import { useEffect, useMemo, useState } from 'react'
import { useFactoryContract } from '../contract/useFactoryContract'
import { useAccounts } from '../polkadotExtension/useAccounts'
import { useApi } from '../useApi'
import { Token } from '@/constants/tokens/types'
import { getPairContract } from '@/utils/contract/getPairContract'
import { getReserves } from '@/utils/pair/getReserves'
import { computeIdealPriceTokenIn } from '@/utils/swap/computeIdealPrice'

interface ReserveResult {
  tokenA: BigNumber
  tokenB: BigNumber
}

export const useIdealPriceOfTokenIn = (bestTradingPath?: string[], tokenIn?: Token, tokenOut?: Token) => {
  // compute idead price of TokenA quoted by TokenB
  // e.g Dot/USDC
  // if the bestRoutePath were Dot/Astar <-> Astar/USDC then
  // ideal price of Dot quoted by USDC would be, Dot/Astar * Astar/USDC = Dot/USDC
  const factory = useFactoryContract()
  const api = useApi()
  const { keyringPair } = useAccounts()
  const [lpReserves, setReserves] = useState<Array<ReserveResult> | undefined>()

  useEffect(() => {
    if (!bestTradingPath || !api || !keyringPair) { return }

    const fetchReservedTokenAmountOflpTokenPair = async () => {
      const reserves: Array<ReserveResult> = []
      for (let i = 0; i < bestTradingPath.length - 1; i++) {
        const lpToken = await factory?.query.getPair(
          bestTradingPath[i],
          bestTradingPath[i + 1]
        )
        if (!lpToken?.value?.ok) return
        const pair = getPairContract(
          lpToken?.value.ok.toString(),
          keyringPair,
          api
        )
        if (!pair) return
        const _reserves = await getReserves(
          pair,
          bestTradingPath[i],
          bestTradingPath[i + 1]
        )
        if (!_reserves) return
        reserves.push(_reserves)
      }
      return reserves
    }

    fetchReservedTokenAmountOflpTokenPair().then(
      amount => setReserves(amount)
    )
  }, [api, bestTradingPath, factory, keyringPair])

  return useMemo(() => {
    if (!lpReserves || !tokenIn || !tokenOut) { return }
    return computeIdealPriceTokenIn(lpReserves, tokenIn, tokenOut)
  }, [lpReserves, tokenIn, tokenOut])
}
