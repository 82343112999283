import Graph from 'graphology'
import { allSimplePaths } from 'graphology-simple-path'
import { useMemo } from 'react'
import { Token } from '@/constants/tokens/types'

interface Prop {
  graph?: Graph
  source?: Token
  destination?: Token
}
export const usePossiblePaths = ({ graph, source, destination }: Prop) => {
  return useMemo(() => {
    if (!graph || !source || !destination) { return }
    return allSimplePaths(graph, source.address, destination.address)
  }, [destination, graph, source])
}
