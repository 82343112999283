import BigNumber from 'bignumber.js'
import { getTokenAmount } from '../token/formatBalance'
import { Token } from '@/constants/tokens/types'

interface ReserveResult {
  tokenA: BigNumber
  tokenB: BigNumber
}

export const computeIdealPriceTokenIn = (lpTokenReserves: Array<ReserveResult>, tokenIn: Token, tokenOut: Token) => {
  let rawPrice = BigNumber(1)
  let decimalIn = tokenIn.decimal
  let decimalOut = tokenOut.decimal
  for (let i = 0; i <= lpTokenReserves.length - 1; i++) {
    const amount = lpTokenReserves[i]
    const rawReserveOfTokenA = amount.tokenA  
    const rawReserveOfTokenB = amount.tokenB
    rawPrice = rawPrice.multipliedBy(rawReserveOfTokenB.dividedBy(rawReserveOfTokenA))
  }
  const price = decimalIn === decimalOut ? rawPrice : getTokenAmount(rawPrice, decimalOut - decimalIn)
  return price
}

