import Graph from 'graphology';
import { LPToken } from '@/constants/lpTokens/types';

export const buildGraph = (lpTokens: LPToken[]) => {
  const graph = new Graph({ type: 'undirected', multi: true });
  lpTokens.forEach(lpToken => {
    const { quoteToken, token } = lpToken
    graph.mergeNode(token.address)
    graph.mergeNode(quoteToken.address)
    graph.mergeEdge(token.address, quoteToken.address)
  })

  return graph
}
