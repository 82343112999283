import BigNumber from 'bignumber.js'
import Graph from 'graphology'
import { useMemo } from 'react'
import { useBestTradeRoutePath } from './useBestTradeRoutePath'
import { useCreateGraphForRoutePath } from './useCreateGraphForRoutePath'
import { usePossiblePaths } from './usePossiblePaths'
import { Token } from '@/constants/tokens/types'
import { useRouterContract } from '@/hooks/contract/useRouterContract'
import { useGetWrappedTokenIfNative } from '@/hooks/token/useGetWrappedTokenIfNative'
import { getDisplayBalanceAmount } from '@/utils/token/formatBalance'
import { TradeType } from '@/constants/tradeType'

interface Prop {
  tokenA?: Token
  tokenB?: Token
  amountIn?: BigNumber
  amountOut?: BigNumber
  graph?: Graph
  tradeType?: TradeType
}

interface Result {
  bestTradePath?: string[]
  amountOut?: BigNumber
  amountIn?: BigNumber
}

export const useBestTrade = ({ tokenA, tokenB, amountIn, amountOut, graph, tradeType }: Prop): Result => {
  const _tokenA = useGetWrappedTokenIfNative(tokenA)
  const _tokenB = useGetWrappedTokenIfNative(tokenB)
  const router = useRouterContract()
  const routeGraph = useCreateGraphForRoutePath(
    { graph, sourceToken: _tokenA, destinationToken: _tokenB }
  )
  const possiblePaths = usePossiblePaths({ graph: routeGraph, source: _tokenA, destination: _tokenB })
  const { bestTradePath, maxAmountOut, minAmountIn } = useBestTradeRoutePath({
    tokenIn: _tokenA,
    tokenOut: _tokenB,
    amountIn,
    amountOut,
    possiblePaths,
    router,
    tradeType
  })

  return useMemo(() => {
    return {
      bestTradePath,
      amountOut: new BigNumber(getDisplayBalanceAmount(maxAmountOut, _tokenB?.decimal)),
      amountIn: new BigNumber(getDisplayBalanceAmount(minAmountIn, _tokenA?.decimal))
    }
  }
    , [bestTradePath, maxAmountOut, _tokenB?.decimal, minAmountIn, _tokenA?.decimal])
}

