import { chainIdByName } from '../chainIdByName'
import { SHIDEN_TOKENS_BY_SYMBOL as tokens } from '../tokens/shiden'
import { ChainIdByLPTokens } from './types'

export const SHIDEN_LP_TOKENS = {
  apolo_sdn: {
    address: 'ZecCT7BaZrXYxZrPJypNq3A57PnQACZJwzjPnG9iyPmUe5s',
    token: tokens.APLO,
    quoteToken: tokens.wSDN,
    chainId: chainIdByName.shiden,
  },
  usdc_sdn: {
    address: 'ayNfpxDUZazkrkXhexvKTW6BHBquUztEEZJRH9qT7hrJtyc',
    token: tokens.USDC,
    quoteToken: tokens.wSDN,
    chainId: chainIdByName.shiden,
  },
  usdt_sdn: {
    address: 'WPmuWw7EAdHZM8GvEAvYVFdgRyFj7FY3jcMLRgBB9SeuWtj',
    token: tokens.USDT,
    quoteToken: tokens.wSDN,
    chainId: chainIdByName.shiden,
  },
}

export const BASIC_LPTOKENS_FOR_ROUTE_PATH_SHIDEN: ChainIdByLPTokens = {
  [chainIdByName.shiden]: [
    SHIDEN_LP_TOKENS.apolo_sdn,
    SHIDEN_LP_TOKENS.usdt_sdn,
    SHIDEN_LP_TOKENS.usdc_sdn,
  ]
}
