import Graph from 'graphology'
import { Token } from '@/constants/tokens/types'

export const createGraphForRoutePath = (graph?: Graph, _source?: Token, _destination?: Token, wNativeToken?: Token) => {
  const routeGraph = graph?.copy()
  routeGraph?.mergeEdge(_source?.address, wNativeToken?.address)
  routeGraph?.mergeEdge(_destination?.address, wNativeToken?.address)

  return routeGraph
}
