import Graph from 'graphology'
import { useMemo } from 'react';
import { useLPTokensForRoutePath } from '@/hooks/token/useLPTokensForRoutePath';
import { buildGraph } from '@/utils/graph/buildGraph';

export const useBuildGraph = (): Graph => {
  const lpTokens = useLPTokensForRoutePath()

  return useMemo(() =>
    buildGraph(lpTokens)
  , [lpTokens])
}
