import BigNumber from 'bignumber.js'
import { Token } from '@/constants/tokens/types'
import Router from '@/utils/types/contracts/router_contract'
import { getDisplayDecimalAmount } from '@/utils/token/formatBalance'

interface Prop {
  tokenIn: Token,
  amountIn: BigNumber,
  possiblePaths: string[][],
  router: Router
}

interface Result {
  bestTradePath: string[]
  maxAmountOut: BigNumber
}

/**
 * search for the path and calc amountOut which
* uses an exact amount of input tokens for as many output tokens as possible,
  */
export const computeBestTradePathForExactAmountIn = async ({
  tokenIn,
  amountIn,
  possiblePaths,
  router
}: Prop): Promise<Result | undefined> => {
  let maxAmountOut = BigNumber(0)
  let bestTradePath: string[] = []
  if (!amountIn.gt(0)) {
    return
  }

  for (const routePath of possiblePaths) {
    const amount = await router?.query.getAmountsOut(
      getDisplayDecimalAmount(amountIn, tokenIn?.decimal),
      routePath
    )

    if (!amount?.value.ok?.ok) {
      console.error('could not get amountout: ', amount.value)
      continue
    }

    const _outAmount = (amount.value.ok.ok as unknown as (string | number)[]).at(-1)
    if (!_outAmount) { return }
    const outAmount = new BigNumber(_outAmount)
    if (outAmount.gt(maxAmountOut)) {
      bestTradePath = routePath
      maxAmountOut = outAmount
    }
  }

  return {
    bestTradePath,
    maxAmountOut
  }
}

interface PropAmountOut {
  tokenOut: Token,
  amountOut: BigNumber,
  possiblePaths: string[][],
  router: Router
}

interface ResultAmountOut {
  bestTradePath: string[]
  minAmountIn: BigNumber
}
/**
 * computeBestTradePathForAmountOut search for the path which
 * receives an exact amount of output tokens for as few input tokens as possible
  */
export const computeBestTradePathForExactAmountOut = async ({
  tokenOut,
  amountOut,
  possiblePaths,
  router
}: PropAmountOut): Promise<ResultAmountOut | undefined> => {
  let minAmountIn = BigNumber(0)
  let bestTradePath: string[] = []
  if (!amountOut.gt(0)) {
    return
  }

  for (const routePath of possiblePaths) {
    const amount = await router?.query.getAmountsIn(
      getDisplayDecimalAmount(amountOut, tokenOut?.decimal),
      routePath
    )

    if (!amount?.value.ok?.ok) {
      console.error('could not get amountout: ', amount.value)
      continue
    }

    const _amountIn = (amount?.value.ok.ok as unknown as (string | number)[]).at(0)
    const amountIn = new BigNumber(_amountIn || '0')
    if (!amountIn.gt(0)) { return }
    if (minAmountIn.eq(0) || amountIn.lt(minAmountIn)) {
      bestTradePath = routePath
      minAmountIn = amountIn
    }
  }

  return {
    bestTradePath,
    minAmountIn
  }
}
