import { Token } from '@/constants/tokens/types'
import { useMemo } from 'react'
import { atom, useRecoilState } from 'recoil'
import type { SetterOrUpdater } from 'recoil'

export interface TokenPrice {
  address: string
  price?: string
}


export const currentTokenPrices = atom<TokenPrice[]>({
  key: 'currentTokenPrices',
  default: [],
})

type Result = [TokenPrice[], SetterOrUpdater<TokenPrice[]>]

export const useTokenPrice = (token?: Token): TokenPrice | undefined => {
  const [tokenPrices] = useRecoilState(currentTokenPrices)

  return useMemo(() => {
    if (!tokenPrices || tokenPrices?.length < 1 || !token) { return }
    return tokenPrices.find(x => x.address == token.address)
  }, [tokenPrices, token])
}

export const useTokenPrices = (): Result => {
  const [tokenPrices, setTokenPrices] = useRecoilState(currentTokenPrices)

  return [
    tokenPrices,
    setTokenPrices
  ]
}
