export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export const toggleTradeType = (currentTradeType?: TradeType) => {
  switch(currentTradeType) {
    case TradeType.EXACT_INPUT:
      return TradeType.EXACT_OUTPUT
    case TradeType.EXACT_OUTPUT:
      return TradeType.EXACT_INPUT
  }
}
