import BigNumber from 'bignumber.js'
import { useEffect, useMemo } from 'react'
import { useGetWrappedTokenIfNative } from './useGetWrappedTokenIfNative'
import { Token } from '@/constants/tokens/types'
import { useAllTokens } from './useTokens'
import { useBestTrade } from '../graph/useBestTrade'
import { useBuildGraph } from '../graph/useBuildGraph'
import { TradeType } from '@/constants/tradeType'
import { useTokenPrice, useTokenPrices } from 'state/tokenPrice'
import { useIdealPriceOfTokenIn } from '../router/useIdealPriceOfTokenIn'
import { useBlockNumber } from 'state/blockNumber'

export const useFetchTokenUSDCPrice = (token?: Token) => {
  const wrappedToken = useGetWrappedTokenIfNative(token)
  const [_, setTokenPrices] = useTokenPrices()
  const tokenPrice = useTokenPrice(token)
  const [blockNumber] = useBlockNumber()
  const tokens = useAllTokens()
  const USDC = useMemo(() => {
    return tokens.find(token => token.symbol === 'USDC')
  }, [tokens])
  const graph = useBuildGraph()
  // BIG_ONE's instance is updated when blockNumber updated.
  // thus each time blockNumber is updated, re-calculate price of given token
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const BIG_ONE = useMemo(() => new BigNumber(1), [blockNumber])

  const { bestTradePath } = useBestTrade({
    tokenA: token,
    tokenB: USDC,
    amountIn: BIG_ONE,
    amountOut: undefined,
    graph,
    tradeType: TradeType.EXACT_INPUT
  })
  const price = useIdealPriceOfTokenIn(bestTradePath, token, USDC)

  useEffect(() => {
    if (price?.gt(0) && token && setTokenPrices && token.isNative && wrappedToken) {
      setTokenPrices((prev) => (
        [...prev,
        { address: token.address, price: price?.toFixed() },
        { address: wrappedToken?.address, price: price?.toFixed() }]
      )
      )
    }

    if (price?.gt(0) && token && setTokenPrices && !token.isNative) {
      setTokenPrices((prev) => ([...prev, { address: token.address, price: price?.toFixed() }]))
    }

  }, [price, setTokenPrices, token, wrappedToken])

  return tokenPrice
}

