import { ApiPromise } from '@polkadot/api'
import type { KeyringPair } from '@polkadot/keyring/types';
import Pair from '@/utils/types/contracts/pair_contract'
import { sentryLogError } from '../env/sentry';

export const getPairContract = (address: string, keyringPair: KeyringPair, api: ApiPromise): Pair | null => {
  try {
    return new Pair(
      address,
      keyringPair,
      api
    )
  } catch (error) {
    sentryLogError(error)
    console.error('Failed to get contract', error)
    return null
  }
}
