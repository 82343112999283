import Graph from 'graphology'
import { useMemo } from 'react'
import { useWrappedNativeToken } from '../token/useWrappedToken'
import { Token } from '@/constants/tokens/types'
import { createGraphForRoutePath } from '@/utils/graph/createGraphForRoutePath'

interface Prop {
  graph?: Graph
  sourceToken?: Token
  destinationToken?: Token
}
export const useCreateGraphForRoutePath = ({
  graph,
  sourceToken,
  destinationToken,
}: Prop) => {
  const wNativeToken = useWrappedNativeToken()
  return useMemo(() => {
    if (!graph || !sourceToken || !destinationToken || !wNativeToken) { return }
    return createGraphForRoutePath(graph, sourceToken, destinationToken, wNativeToken)
  }
    , [graph, sourceToken, destinationToken, wNativeToken])
}
