import { useChainId } from '../useApi'
import { BASIC_LPTOKENS_FOR_ROUTE_PATH } from '@/constants/lpTokens'
import { LPToken } from '@/constants/lpTokens/types'

export const useLPTokensForRoutePath = (): LPToken[] | [] => {
  const chainId = useChainId()
  if (!chainId) {
    return []
  }
  return BASIC_LPTOKENS_FOR_ROUTE_PATH[chainId] || []
}
